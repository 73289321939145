
export const setCookie = function (name, value, expiry_days) {
    var d = new Date();
    d.setTime(d.getTime() + (expiry_days * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
    return getCookie(name);
};

export const getCookie = function (name) {
    var cookie_name = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(cookie_name) === 0) {
            return c.substring(cookie_name.length, c.length);
        }
    }
    return false;
};

export const dropCookie = function (value, expiryDays) {
    setCookie("cookieControl", value, expiryDays);
};


class CookieConsent {
    constructor(options) {
        this.options = Object.assign({
            element: "body",
            cookieTypes: [
                {
                    type: "Site Preferences",
                    value: "preferences",
                    description: "These are cookies that are related to your site preferences, e.g. remembering your username, site colours, etc."
                },
                {
                    type: "Analytics",
                    value: "analytics",
                    description: "Cookies related to site visits, browser types, etc."
                },
                {
                    type: "Marketing",
                    value: "marketing",
                    description: "Cookies related to marketing, e.g. newsletters, social media, etc"
                }
            ],
            title: "Cookies & Privacy",
            message: "Cookies enable you to use shopping carts and to personalize your experience on our sites, tell us which parts of our websites people have visited, help us measure the effectiveness of ads and web searches, and give us insights into user behavior so we can improve our communications and products.",
            link: "/privacy-policy",
            delay: 2000,
            expires: 30,
            moreInfoLabel: "More information",
            acceptBtnLabel: "Accept Cookies",
            advancedBtnLabel: "Customise Cookies",
            cookieTypesTitle: "Select cookies to accept",
            fixedCookieTypeLabel: "Necessary",
            fixedCookieTypeDesc: "These are cookies that are essential for the website to work correctly.",
            onAccept: function () { },
            uncheckBoxes: false
        }, options);

        this.$element = document.querySelector(this.options.element);

        var myCookie = getCookie("cookieControl");
        var myCookiePrefs = getCookie("cookieControlPrefs");

        if (!myCookie || !myCookiePrefs) {
            // Remove all instances of the cookie message so it's not duplicated
            if (document.querySelector("#gdpr-cookie-message")) {
                document.querySelector("#gdpr-cookie-message").remove();
            }

            // Set the 'necessary' cookie type checkbox which can not be unchecked
            var cookieTypes = "<li><input type=\"checkbox\" name=\"gdpr[]\" value=\"necessary\" checked=\"checked\" disabled=\"disabled\"> <label title=\"" + this.options.fixedCookieTypeDesc + "\">" + this.options.fixedCookieTypeLabel + "</label></li>";

            // Generate list of cookie type checkboxes
            //let preferences = JSON.parse(myCookiePrefs);

            this.options.cookieTypes.forEach((field) => {
                if (field.type !== "" && field.value !== "") {
                    var cookieTypeDescription = "";
                    if (field.description !== false) {
                        cookieTypeDescription = " title=\"" + field.description + "\"";
                    }
                    cookieTypes += "<li><input type=\"checkbox\" id=\"gdpr-cookietype-" + field.value + "\" name=\"gdpr[]\" value=\"" + field.value + "\" data-auto=\"on\"> <label for=\"gdpr-cookietype-" + field.value + "\"" + cookieTypeDescription + ">" + field.type + "</label></li>";
                }
            });

            // Display cookie message on page
            var cookieMessage = "<div id=\"gdpr-cookie-message\"><h4>" + this.options.title + "</h4><p>" + this.options.message + " <a href=\"" + this.options.link + "\">" + this.options.moreInfoLabel + "</a><div id=\"gdpr-cookie-types\" style=\"transition-duration: 0.5s; transition-property: max-height; max-height: 0px; overflow-y: hidden;\"><h5>" + this.options.cookieTypesTitle + "</h5><ul>" + cookieTypes + "</ul></div><p><button id=\"gdpr-cookie-accept\" type=\"button\">" + this.options.acceptBtnLabel + "</button><button id=\"gdpr-cookie-advanced\" type=\"button\">" + this.options.advancedBtnLabel + "</button></p></div>";
            setTimeout(function () {
                const container = document.createElement("div");
                container.innerHTML = cookieMessage;
                this.$element.append(container);

                this.addEventListeners();

                // Think this might be a jquery specific "event"?
                // $("#gdpr-cookie-message").hide().fadeIn("slow", function () {
                //     // If reinit'ing, open the advanced section of message
                //     // and re-check all previously selected options.
                //     if (event == "reinit") {
                //         $("#gdpr-cookie-advanced").trigger("click");
                //         $.each(preferences, function (index, field) {
                //             $("input#gdpr-cookietype-" + field).prop("checked", true);
                //         });
                //     }
                // });

            }.bind(this), this.options.delay);

        } else {
            let cookieVal = true;
            if (myCookie == "false") {
                cookieVal = false;
            }
            dropCookie(cookieVal, this.options.expires);

            if(document.getElementById("gdpr-cookie-message")){
                document.getElementById("gdpr-cookie-message").remove();
            }
        }

        // Uncheck any checkboxes on page load
        if (this.options.uncheckBoxes === true) {
            this.$element.querySelectorAll("input[type='checkbox']").forEach(ele => {
                ele.removeAttribute("checked");
                ele.checked = false;
            });
        }
    }

    cookie() {
        var preferences = getCookie("cookieControlPrefs");
        return JSON.parse(preferences);
    }

    preference(cookieTypeValue) {
        var control = getCookie("cookieControl");
        var preferences = getCookie("cookieControlPrefs");
        preferences = JSON.parse(preferences);
        if (control === false) {
            return false;
        }
        if (preferences === false || preferences.indexOf(cookieTypeValue) === -1) {
            return false;
        }
        return true;
    }

    addEventListeners() {
        // When accept button is clicked drop cookie
        this.$element
            .querySelector("#gdpr-cookie-accept")
            .addEventListener("click", function () {
                // Set cookie
                dropCookie(true, this.options.expires);

                // If 'data-auto' is set to ON, tick all checkboxes because
                // the user hasn't clicked the customise cookies button
                this.$element.querySelectorAll("input[name=\"gdpr[]\"][data-auto=\"on\"]").forEach(ele => {
                    ele.setAttribute("checked", true);
                    ele.checked = true;
                });

                // Save users cookie preferences (in a cookie!)
                var prefs = [];
                this.$element.querySelectorAll("input[name='gdpr[]']").forEach(ele => {
                    // console.log(ele, ele.checked);
                    if(ele.checked){
                        prefs.push(ele.value);
                    }
                });

                // console.log(prefs, JSON.stringify(prefs), encodeURIComponent(JSON.stringify(prefs)));
                setCookie("cookieControlPrefs", encodeURIComponent(JSON.stringify(prefs)), 365);

                if(document.getElementById("gdpr-cookie-message")){
                    document.getElementById("gdpr-cookie-message").remove();
                }

                // Run callback function
                this.options.onAccept.call(this);
            }.bind(this));

        // Toggle advanced cookie options
        this.$element
            .querySelector("#gdpr-cookie-advanced")
            .addEventListener("click", function () {
                this.$element.querySelectorAll("input[name='gdpr[]']:not(:disabled)").forEach(ele => {
                    ele.setAttribute("data-auto", "off");
                    ele.removeAttribute("checked");
                    ele.checked = false;
                });

                const types = this.$element.querySelector("#gdpr-cookie-types");
                this.$element.querySelector("#gdpr-cookie-advanced").setAttribute("disabled", true);

                types.style.maxHeight = "300px";
            }.bind(this));
    }

}

export default CookieConsent;
